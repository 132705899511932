#login-container{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  height: 100vh;
}

#rays {
  z-index: 2;
  position: relative;
  bottom: -1.5em;
  animation: rays 2s ease-in-out infinite;
}

.login-form {
  position: relative;
  top: 5em;
  padding: 3%;
  z-index: 3;
  display: flex;
  flex-direction: column;
  border-radius: 0.5rem;
  border: 4px solid #fff;
  background: var(--login-background);
  box-shadow: var(--login-box-shadow);
  backdrop-filter: blur(3.5px);
  gap: 1em;
  animation: float 3s ease-in-out infinite;
}

#login-lable {
  text-align: center;
  color: white;
  font-size: 2rem;
  font-weight: 600;
  letter-spacing: 8px;
  text-shadow: 0px 0px 16px rgb(243, 243, 243);
}

.login-form-content {
  height: 2.5em;
  padding: 1px 8px;
  color: white;
  text-decoration: none;
  letter-spacing: 1px;
  font-weight: bold;
  border-radius: 6px;
  border: 2px solid #fff;
  background: rgba(139, 255, 247, 0.486);
  box-shadow: 0px 0px 1px 3px #9ee5e3 inset, 0px 4px 4px 0px #181a6040;
  text-shadow: 0px 1px 4px rgb(243, 243, 243);
}

.login-form-content:focus-visible {
  outline: none;
  text-decoration: none;
  background: rgba(139, 189, 255, 0.59);
  box-shadow: 0px 0px 1px 4px #9ee5e3;
}

.login-form-content:hover {
  background: rgba(139, 189, 255, 0.59);
}

::placeholder {
  font-weight: 300;
  color: white;
  letter-spacing: 0.1rem;
  text-shadow: 0px 1px 5px rgb(66, 66, 66);
}

.login-form button {
  cursor: pointer;
  height: 2.9rem;
  padding: 0%;
  color: white;
  font-size: 1.5em;
  letter-spacing: 0.3rem;
  border: 2px solid white;
  background: linear-gradient(144deg, #9c11ffce, #2000eeb6 50%, #15efffbb);
}

.login-form button:hover {
  position: relative;
  bottom: 4px;
  background: linear-gradient(144deg, #9c11ff, #2000ee 50%, #15fff3);
  box-shadow: 0px 0px 2px 2px #ffffff;
}

.authTitle {
  text-align: center;
  font-size: 1em;
  font-weight: 600;
  text-shadow: 0px 0px 16px rgb(90, 89, 89);
}

.auth-links {
  position: relative;
  top: 1em;
  color: white;
  font-size: 1rem;
  font-weight: 600;
  letter-spacing: 1px;
  text-shadow: 0px 0px 16px rgb(243, 243, 243);
}

.auth-links:hover {
  color: #ff00ff;
  cursor: pointer;
  text-decoration: none;
}

.login-btn {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5em;
  padding: 0.5em 1em;
  border-radius: 0.5rem;
  border: 2px solid #fff;
  background: rgba(139, 255, 247, 0.486);
  box-shadow: 0px 0px 1px 3px #9ee5e3 inset, 0px 4px 4px 0px #181a6040;
  text-shadow: 0px 1px 4px rgb(243, 243, 243);
}

.google-btn-login{
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5em;
  padding: 0.5em 1em;
  border-radius: 0.5rem;
  border: 2px solid #fff;
  background: rgba(139, 255, 247, 0.486);
  box-shadow: 0px 0px 1px 3px #9ee5e3 inset, 0px 4px 4px 0px #181a6040;
  text-shadow: 0px 1px 4px rgb(243, 243, 243);
}

.S9gUrf-YoZ4jf iframe {
  background-color: transparent;
}

/* SignUp */

.signup-form {
  position: relative;
  top: 5em;
  padding: 2%;
  z-index: 3;
  display: flex;
  flex-direction: column;
  border-radius: 0.5rem;
  border: 4px solid #fff;
  background: rgba(0, 255, 240, 0.52);
  box-shadow: 0px 0px 64px 0px #82e1ff inset, 0px 0px 16px #a8fffaa6;
  backdrop-filter: blur(3.5px);
  gap: 1em;
  animation: float 3s ease-in-out infinite;
}

::placeholder {
  font-weight: 300;
  color: white;
  letter-spacing: 0.1rem;
  text-shadow: 0px 1px 5px rgb(66, 66, 66);
}

.signup-form button {
  cursor: pointer;
  height: 2rem;
  padding: 0%;
  color: white;
  font-size: 1em;
  letter-spacing: 0.3rem;
  border: 2px solid white;
  background: linear-gradient(144deg, #9c11ffce, #2000eeb6 50%, #15efffbb);
}

.signup-form button:hover {
  position: relative;
  bottom: 4px;
  background: linear-gradient(144deg, #9c11ff, #2000ee 50%, #15fff3);
  box-shadow: 0px 0px 2px 2px #ffffff;
}

.signup-form-content {
  height: 2em;
  padding: 1px 8px;
  color: white;
  text-decoration: none;
  letter-spacing: 1px;
  font-weight: bold;
  border-radius: 6px;
  border: 2px solid #fff;
  background: rgba(139, 255, 247, 0.486);
  box-shadow: 0px 0px 1px 3px #9ee5e3 inset, 0px 4px 4px 0px #181a6040;
  text-shadow: 0px 1px 4px rgb(243, 243, 243);
}

.signup-links {
  position: relative;
  color: white;
  font-size: 0.9rem;
  font-weight: 600;
  letter-spacing: 1px;
  text-shadow: 0px 0px 16px rgb(243, 243, 243);
}

.signup-links:hover {
  color: #ff00ff;
  cursor: pointer;
  text-decoration: none;
}

.signup-txt {
  position: relative;
  color: white;
  font-size: 0.9rem;
  font-weight: 600;
  letter-spacing: 1px;
  text-shadow: 0px 0px 16px rgb(243, 243, 243);
}

@keyframes float {
  0% {
    position: relative;
  }

  50% {
    top: 50px;
  }

  100% {
    position: relative;
  }
}

@keyframes rays {
  0% {
    opacity: 0.6;
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 0.6;
  }
}
