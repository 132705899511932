.post-body {
  background-color: rgba(29, 29, 29, 0.5);
  color: #cccccc;
  font-family: 'Georgia', 'Times New Roman', Times, serif;
  padding-bottom: 3em;
  padding-top: 10vh;
}

.edit-post-div {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 1em;
}

.edit-post-button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px 15px;
  margin: 10px 5px;
  border-radius: 5px;
  cursor: pointer;
}

.edit-post-button:active {
  outline: none;
  transform: translateY(4px);
}

.edit-post-button:focus {
  outline: none;
}

.edit-post-button:hover {
  background-color: #0056b3;
}

.post-profile {
  box-shadow: inset 0 0 5em 1em #000;
  background-size: cover;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  height: auto;
  z-index: -1;
}

.post-hr {
  overflow: visible;
  padding: 1em 0;
  border: none;
  border-top: medium double #333;
  color: #333;
  text-align: center;
}

.post-hr:after {
  content: "''";
  display: inline-block;
  position: relative;
  top: -0.7em;
  font-size: 1.5em;
  padding: 0 0.25em;
  background: #181819;
}

.post-article {
  margin: 7vh auto;
  position: relative;
  max-width: 66em;
  height: auto;
  padding: 20px;
  background-color: rgba(29, 29, 29, 0.5);
  box-shadow: 0px 10px 20px rgba(0,0,0,0.19), 0px 6px 6px rgba(0,0,0,0.23);
  border-radius: 15px;
}

.content-block {
  margin: 0 auto 2em auto;
  max-width: 66em;
  height: auto;
  padding: 20px;
  box-shadow: 0px 10px 20px rgba(0,0,0,0.19), 0px 6px 6px rgba(0,0,0,0.23);
  border-radius: 15px;
}

.post-header {
  text-align: center;
  padding: 0 2em;
}

.post-h1 {
  text-transform: uppercase;
  font-weight: 400;
  color: #ebcb34; 
}

.post-h1,
.post-headline-2 {
  line-height: 1;
  font-size: 2vw;
}

.subtitle-h4 {
  text-transform: uppercase;
  font-weight: 400;
  color: #ebcb34; 
  text-align: start;
  margin-left: 10vw;
  margin-bottom: 3vw;
}

.post-headline-2 {
  margin-bottom:3vh;
}

.post-headline-1 {
  font-size: 3.5vw;
}

.post-section {
  /* 2 columns */
  /* column-width: 20em; */  
  /* column-gap: 1.33em; */
  padding: 0 3em;
  text-align: justify;
}
    
.post-section p:first-of-type:first-letter {
  float: left;
  font-size: 2em;
  line-height: 1;
  margin-top: -0.3em;
  margin-bottom: -0.2em;
  margin-right: 0.1em;
  color: #ebcb34;
}

.post-img {
  width: 100%; 
  height: 50vh; 
  object-fit: cover; 
  margin: 10vh auto; 
  display: block; 
}

.post-blockquote {
  border-left: 0.5em solid #ccc;
  font-size: 2em;
  padding: 0.5em;
  line-height: 1.5;
  margin-left: 0;
  margin-right: 0;
  position: absolute;
  right: 100%;
  top: 70vh;
  width: 9em;
  background: #191919;
}

.post-blockquote::before,
.post-blockquote::after {
  color: #ccc;
  font-size: 1.25em;
  line-height: 0;
}

.post-blockquote::before {
  content: open-quote;
}

.post-blockquote::after {
  content: close-quote;
}

.code-card {
  max-height: 100%;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  margin: 0 2vw 10vh 2vw;
}

pre, code {
  white-space: pre-wrap !important;
  word-break: break-word !important;
  overflow-wrap: break-word !important;
}

.card-editable {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.post-code-editable {
  background-color: rgb(1, 22, 39);
  border-radius: 0 0 5px 5px;
  font-family: 'Courier New', monospace !important;
  font-size: 1.25em;
  margin: 5vh 2vw 5vh 2vw;
  color: #cccccc;
  width: calc(100% - 10%);
  padding: 10px;
  margin-bottom: 1em;
  border: 1px solid #ddd;
  border-radius: 5px;
  box-sizing: border-box;
  resize: vertical;
  overflow: auto;
  outline: none;
}

.code-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-radius: 10px 10px 0 0;
  background-color: rgb(1, 22, 39);
}

.post-code {
  background-color: rgb(1, 22, 39);
  border-radius: 0 0 5px 5px;
  padding: 15px;
  font-family: 'Courier New', monospace !important;
  font-size: 1.25em;
  margin: 0 !important;
  max-width: auto;
  max-height: 100em;
  overflow-x: auto;
  margin-bottom: 2em;
  border-radius: 0 0 10px 10px;
}

.code-language {
  font-weight: bold;
  text-align: center;
  text-transform: uppercase;
  background-color: rgb(1, 22, 39);
  color: white;
  margin: 13px 15px;
}

.copy-button {
  font-size: 16px;
  font-weight: bold;
  background-color: rgb(1, 22, 39);
  color: white;
  border: none;
  padding: 11px 15px 12px 15px;
  text-transform: uppercase;
  cursor: pointer; 
  border-radius: 0 10px 0 0;
}

.copy-button:active {
  outline: none;
  transform: translateY(4px);
}

.copy-button:focus {
  outline: none;
}

/* editable content */
.post-h1-editable {
  font-size: 3vw;
  font-weight: 400;
  color: #ebcb34; 
  line-height: 1;
  text-transform: uppercase;
  margin: 0;
  padding: 15px;
}

.post-description-editable {
  width: calc(100% - 22px);
  padding: 10px;
  margin-bottom: 1em;
  border: 1px solid #ddd;
  border-radius: 5px;
  box-sizing: border-box;
  resize: vertical;
  overflow: auto;
  outline: none;
  margin-top: 1em;
}

.post-text-editable {
  font-size: 1.5em;
  font-weight: 300;
  color: #000;
  line-height: 1.5;
  margin: 0;
  padding: 15px;
  width: 60vw;
  resize: vertical;
  overflow: auto;
}

@media (max-width: 768px) {
  .post-section {
    padding: 0 0.1em; 
  }

  .post-h1,
  .subtitle-h4,
  .post-headline-1,
  .post-headline-2 {
    font-size: 4vw; 
  }

  .post-text-editable,
  .post-description-editable,
  .post-code-editable,
  .post-h1-editable {
    width: 100%; 
    padding: 0.5em; 
    font-size: 4vw; 
  }

  .copy-button {
    padding: 12px 5px;
    max-width: 100%;
  }

  .code-language {
    font-size: 3vw; 
    padding: 5px 5px;
    max-width: 100%; 
  }
  
  .code-card, .copy-button, .post-code {
    max-width: 100%;
    font-size: 3vw; 
  }
}
