.new-proj-container-wrapper {
  height: 100vh;
  background-color: #000000;
}
.new-proj-container,
.new-proj-nav {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
*,
*::before,
*::after {
  box-sizing: border-box;
}

/* Nav */
.new-proj-nav {
  width: 100%;
  height: 12vh;
  background-color: rgb(0, 0, 0);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
}
.new-proj-nav-left {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 10px;
}
.new-proj-nav-title {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 10px;
  color: white;
  font-size: 1.5rem;
  font-weight: 600;
  margin-left: 15vh;
}
.new-proj-title {
  color: white;
  font-size: 1.5rem;
  font-weight: 600;
  margin-top: 3vh;
}
.new-proj-nav-title input {
  background-color: rgb(61, 61, 61);
  color: white;
  outline: none;
  font-size: 1.2rem;
  font-weight: 700;
  border: none;
  border-radius: 5px;
  padding: 3px;
}
.new-proj-nav-title-icon svg {
  width: 35px;
  height: 35px;
}

.new-proj-nav-title-icon:hover {
  cursor: pointer;
}
.save-proj-button {
  background-color: black;
  color: white;
  outline: none;
  font-size: 1.1rem;
  font-weight: 400;
  border-radius: 5px;
  padding: 4px;
  margin-left: 3vh;
  cursor: pointer;
  border: #ddd 1.8px solid;
}
.new-proj-nav-right {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 10px;
}
.layot-button {
  background-color: black;
  color: white;
  outline: none;
  font-size: 1.1rem;
  font-weight: 400;
  border-radius: 5px;
  padding: 4px;
  margin-left: 3vh;
  cursor: pointer;
  border: #ddd 1.8px solid;
}

.new-proj-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 83vh;
  background-color: #000000;
}

.editor {
  background-color: black;
  color: white;
  outline: none;
  font-size: 1.1rem;
  font-weight: 400;
  border-radius: 5px;
  padding: 4px;
  margin-left: 3vh;
  cursor: pointer;
  border: #ddd 1.8px solid;
  height: 50%;
}
.login-button {
  background-color: transparent;
  color: white;
  outline: none;
  font-size: 1.1rem;
  font-weight: 400;
  border-radius: 5px;
  padding: 4px;
  margin-left: 3vh;
  cursor: pointer;
  border: #ddd 1.8px solid;
}
.new-proj-acc-logo {
  height: 40px;
  width: 40px;
  border-radius: 50%;
}
.new-proj-acc-logo:hover {
  cursor: pointer;
}
/* code */
.split-sash-content {
  background-color: black;
  padding: 8px;
}
.code-editor-head {
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 30px;
}

.lang-icon {
  min-width: min-content;
  background-color: #282c34;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 15px;
  padding: 15px;
}

.lang-icon svg {
  width: 30px;
  height: 30px;
}

.lang-icon svg:hover {
  cursor: pointer;
  background-color: black;
}

.right-tools {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  gap: 5px;
}

.right-tools svg {
  width: 25px;
  height: 25px;
  background-color: #282c34;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 5px;
  padding: 2px;
}

.right-tools svg:hover {
  cursor: pointer;
}

.language-switcher {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 5px;
  background-color: #282c34;
  padding: 5px;
  border-radius: 5px 5px 0 0;
  color: white;
  font-size: 1rem;
  font-weight: 600;
  margin: 0;
}

.language-switcher button {
  background-color: #282c34;
  color: white;
  outline: none;
  font-size: 1rem;
  font-weight: 400;
  cursor: pointer;
  border: none;
  border-radius: 5px;
  padding: 5px 10px;
}

.code-editor,
.output-section {
  width: 100%;
  height: 100%;
}

.code-editor-component {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  background-color: #282c34;
  padding: 0;
  margin: 0;
  border: none;
  overflow: hidden;
}

.code-editor {
  flex-grow: 1;
  background-color: #282c34;
  overflow: auto;
  padding: 0;
  margin: 0;
  border: none;
}

.code-editor .cm-scroller::-webkit-scrollbar {
  width: 10px;
  background: transparent;
}

.code-editor .cm-scroller::-webkit-scrollbar-thumb {
  background: rgba(255, 255, 255, 0.2);
}

.code-editor .cm-scroller {
  scrollbar-width: thin;
  scrollbar-color: rgba(255, 255, 255, 0.2) transparent;
}

/* Output */

.output-section {
  background-color: #282c34;
  width: 100%;
  height: 83vh;
}

.output-section.vertical {
  border-left: 30px solid #000000;
}

.output-section.horizontal {
  border-top: none;
}

iframe {
  background-color: #282c34;
  width: 100%;
  height: 83vh;
  border: none;
}

.output-footer-bar {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background-color: #282c34;
  padding: 0 25px;
}

.output-footer-bar.horizontal {
  margin-top: 10px;
}

.output-footer-bar.vertical {
  margin-left: 30px;
}

.output-footer-bar-left button {
  background-color: rgb(61, 61, 61);
  color: white;
  outline: none;
  font-size: 1rem;
  font-weight: 400;
  cursor: pointer;
}

.output-footer-bar-right {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
}

.output-footer-bar-left svg {
  width: 25px;
  height: 25px;
  background-color: #282c34;
  margin: 2.4px 5px;
  padding: 2px;
  cursor: pointer;
}

.output-footer-bar-center svg {
  width: 25px;
  height: 25px;
  background-color: #282c34;
  margin: 0 5px;
  padding: 2px;
  cursor: pointer;
}

.output-footer-bar-right svg {
  width: 25px;
  height: 25px;
  background-color: #282c34;
  margin: 0 5px;
  padding: 2px;
  cursor: pointer;
}

.output-footer-bar-right p {
  color: white;
  font-size: 1rem;
  font-weight: 400;
  margin: 0;
}

.output-iframe {
  height: auto;
  min-height: 83vh;
  width: 100%;
  border: none;
  background-color: #282c34;
}

.code-editor-head-horizontal {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.scroll-to-output-btn {
  padding: 5px 10px;
  cursor: pointer;
  background-color: #282c34;
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 1rem;
  font-weight: 600;
  outline: none;
}

.nav-logo1 {
  width: 100px;
  cursor: pointer;
  margin: auto;
}

@media (max-width: 768px) {
  .new-proj-nav {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    height: auto;
    padding: 10px;
  }

  .nav-logo1 {
    margin: 0 20px;
  }

  .new-proj-nav-left {
    justify-content: flex-start;
    width: 100%;
  }

  /* .new-proj-nav-right { */

  .new-proj-nav-title {
    margin-left: 0;
    justify-content: center;
    gap: 5px;
  }

  .save-proj-button,
  .layot-button,
  .login-button {
    padding: 2px 5px;
    font-size: 0.9rem;
    margin-left: 0;
  }

  .new-proj-acc-logo {
    width: 30px;
    height: 30px;
  }

  .new-proj-nav-title input {
    font-size: 1rem;
  }
  .new-proj-title {
    font-size: 1.2rem;
  }
  .save-proj-button,
  .editor,
  .login-button {
    font-size: 0.7rem;
  }
  .new-proj-nav-title-icon svg {
    width: 30px;
    height: 30px;
  }
  .language-icons-btn svg {
    width: 27px !important;
    height: 27px !important;
  }
  .scroll-to-output-btn {
    padding: 5px 10px;
    cursor: pointer;
    background-color: #282c34;
    color: white;
    border: none;
    border-radius: 5px;
    font-size: 0.8rem;
    font-weight: 600;
    outline: none;
  }
}

@media (max-width: 340px) {
  .nav-logo1 {
    display: none;
  }
}
