.card {
  position: relative;
  height: 250px;
  width: 250px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
  transition: box-shadow 0.3s ease;
  border-radius: 20px;
}

.card-body {
  position: relative;
  height: 250px;
  width: 250px;
  border-radius: 20px;
}

.iframe {
  width: calc(200% + 5px);
  height: calc(200% + 5px);
  border: 0;
  position: absolute;
  top: -2px;
  left: -2px;
  transform: scale(0.5);
  transform-origin: top left;
  border-radius: 20px;
}

.overlay {
  position: absolute;
  top: -10px;
  left: -10px; 
  right: -10px; 
  bottom: -10px; 
  border-radius: 20px; 
  box-shadow: 0 0 15px 10px var(--shadow-color); 
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: opacity 0.8s ease;
  pointer-events: none;
  gap: 10px;
  --webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(3px);
}

.overlay-hidden {
  display: none;
}

.card:hover .overlay {
  opacity: 1;
  pointer-events: auto;
}

.post-card-footer {
  width: 250px;
  padding: 10px;
}

.view-btn {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px 20px;
  font-size: 16px;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.view-btn:hover {
  background-color: #0056b3;
}

.view-btn:active {
  background-color: #004085;
}

.view-btn:focus {
  outline: none;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.view-btn:disabled {
  background-color: #007bff;
  opacity: 0.65;
  pointer-events: none;
  cursor: not-allowed;
}

.post-card-title {
  font-size: 18px;
  font-weight: bold;
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
  margin: 0;
  margin-top: 15vh; 
  background: none;
  -webkit-text-fill-color: initial;
  letter-spacing: 1px;
  text-align: center; 
}

.post-card-icons {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 15px;
  cursor: pointer;
  margin-top: 20px;
}


.number-of {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px; 
  color: #007bff; 
  font-weight: bold;
  padding: 5px 10px;
  border-radius: 50px; 
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease;
  background-color: rgba(255, 255, 255, 0.8);
}

.number-of:hover {
  background-color: rgba(255, 255, 255, 1);
  transform: scale(1.05); 
}

.soc-icons {
  fill: #007bff; 
  width: 16px; 
  height: 16px; 
}

.soc-number {
  font-size: 15px;
  color: #007bff; 
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.ref-style {
  height: 20px;
   margin: 10px 0;
}

.close-overlay-btn {
  position: absolute;
  top: 5px;
  right: 10px;
  border: none;
  background: none;
  color: white; 
  font-size: 25px;
  cursor: pointer;
}

.close-overlay-btn:hover {
  color: #ff0000;
}

.eye-look-btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 5px 10px;
  border: none;
  border-radius: 50%; 
  cursor: pointer;
  background-color: rgba(255, 255, 255, 0.8);
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.eye-look-btn:hover, .eye-look-btn:focus {
  background-color: rgba(255, 255, 255, 1);
  transform: scale(1.1);
}

.eye-look-btn svg {
  fill: #007bff; 
  width: 24px; 
  height: 24px;
}

@keyframes blink {
  0%, 100% { opacity: 1; }
  50% { opacity: 0.0; }
}

.view-icon {
  animation: blink 1.5s infinite; 
}

.view-icon:hover {
  animation: none; 
}

.delete-snippet-btn {
  background-color: transparent;
  border: none; 
  cursor: pointer;
  padding: 5px; 
  display: flex; 
  justify-content: center; 
  align-items: center; 
}

.delete-icon {
  fill: #ff0000; 
  width: 20px; 
  height: 20px;
  transition: transform 0.3s ease; 
}

.delete-snippet-btn:hover .delete-icon {
  transform: scale(1.1); 
}

@media (max-width: 768px) {
  .overlay, .overlay-hidden {
    opacity: 1;
    pointer-events: auto; 
    background-color: rgba(0, 0, 0, 0.7);
    color: white;
  }

  .iframe {
    width: 100%; 
    height: 100%; 
    transform: scale(1); 
  }

  .post-card-title {
    font-size: 16px; 
    background: none;
    -webkit-text-fill-color: initial; 
  }

  .post-card-icons {
    gap: 10px; 
  }

  .number-of, .view-btn {
    padding: 5px 10px; 
    font-size: 12px;
  }

  .soc-icons {
    height: 15px; 
    width: 15px;
  }
}
