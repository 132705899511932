.learn-card-body {
	width: 100%;
	min-height: 100%;
	display: flex;
  }
  
  .learn-container {
	flex: 1;
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	grid-template-rows: repeat(2, 1fr);
  }
  
  .learn-card {
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
	margin: 10px;
	background: linear-gradient(rgba(25, 31, 50, 0.2), rgb(4 8 20 / 0.01)),
	  rgb(4 8 20 / 0.8);
	box-shadow: inset 0 0 0 1px rgb(200 200 220 / 0.16),
	  inset 0 0 5px -3px var(--color), inset 0 12px 48px 0 rgb(160 220 240 / 0.08),
	  inset 0 0 120px -100px var(--color);
	border-radius: 16px;
	color: var(--color);
	overflow: hidden;
  }
  .learn-card::before {
	content: "";
	position: absolute;
	inset: 0;
	background-image: radial-gradient(var(--color) 8%, transparent 8%),
	  radial-gradient(var(--color) 8%, transparent 8%),
	  radial-gradient(var(--color) 8%, transparent 8%),
	  radial-gradient(var(--color) 8%, transparent 8%);
	background-position: 0% 0%, 0% 100%, 100% 0%, 100% 100%;
	background-size: 40px 40px;
	background-repeat: no-repeat;
	filter: drop-shadow(0 0 6px var(--color));
  }
  .learn-card::after {
	content: "";
	position: absolute;
	inset: 0;
	background-color: var(--color);
	opacity: 0.2;
	-webkit-mask-image: radial-gradient(90% 90% at 50% 50%, transparent, black);
	mask-image: radial-gradient(90% 90% at 50% 50%, transparent, black);
	filter: url(#noiseFilter);
	z-index: 1;
  }
  
  .learn-button {
	--w: 180px;
	--h: 60px;
	--icon-size: 25px;
	--box-glow-color: transparent;
	--box-glow-blur: 20px;
	position: relative;
	min-width: var(--w);
	min-height: var(--h);
	border-radius: var(--h);
	display: flex;
	justify-content: center;
	align-items: center;
	border: none;
	font-family: "Montserrat";
	color: rgb(210 210 240);
	background: rgb(4 8 20 / 0.8);
	box-shadow: 0 0 0 1px rgb(200 200 220 / 0.22),
	  0 0 var(--box-glow-blur) var(--box-glow-color),
	  inset 0 0 26px -10px var(--box-glow-color);
	overflow: hidden;
	cursor: pointer;
	transition: box-shadow 500ms ease;
	z-index: 2;
  }
  .learn-button:hover {
	box-shadow: 0 0 0 1px rgb(200 200 220 / 0.22),
	  0 0 var(--box-glow-blur) var(--color), inset 0 0 26px -10px var(--color);
	text-decoration: none;
  }
  .learn-button::before {
	content: "";
	position: absolute;
	inset: 0;
	background: rgb(200 200 220 / 0.1);
	box-shadow: inset 0 0px 24px 0 rgb(170 230 250 / 0.12);
	border-radius: var(--h);
	z-index: 1;
	transition: transform 500ms ease, box-shadow 500ms ease;
  }
  
  .learn-text,
  .learn-icon {
	z-index: 2;
  }
  
  .learn-text {
	transform: translateX(-50%);
	font-family: "Montserrat";
	transition: transform 500ms ease;
  }
  
  .learn-icon {
	position: absolute;
	width: var(--icon-size);
	height: var(--icon-size);
	left: 0;
	transform: translateX(calc((var(--w)) / 2 + 8px));
	transition: transform 500ms ease;
  }
  
  .learn-icon svg {
	width: 100%;
	height: 100%;
	fill: rgb(210 210 240);
  }
  
  .learn-button:active {
	--box-glow-blur: 36px;
  }
  .learn-button:hover,
  .learn-button:active {
	--box-glow-color: var(--color);
  }
  .learn-button:hover::before,
  .learn-button:active::before {
	transform: translateX(65%);
	box-shadow: inset 0 0px 0px 0 transparent;
  }
  .learn-button:hover .learn-text,
  .learn-button:active .learn-text {
	transform: translateX(0%);
	animation-play-state: running;
  }
  .learn-button:hover .learn-icon,
  .learn-button:active .learn-icon {
	transform: translateX(calc(var(--w) - var(--icon-size) - 19px));
  }
  
  .learn-button:hover .learn-icon svg {
	fill: var(--color);
  }
  
  .learn-button:focus-visible {
	outline: none;
  }
  .learn-button:focus-visible:not(:hover, :active) {
	--box-glow-color: rgb(114 114 114 / 0.5);
  }
  
  .javascript-course {
	--color: #00afaf;
  }
  
  .react-course {
	--color: #00bb00;
  }
  
  .nodejs-course {
	--color: #0f2bff;
  }
  
  .redux-course {
	--color: #ff6a2f;
  }
  
  
  @media (max-width: 500px) {
	.learn-container {
	  grid-template-columns: 1fr;
	  grid-template-rows: auto;
	}
  }
  