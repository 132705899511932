.myForm-container {
  background: white;
  padding: 2em;
  border-radius: 10px;
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.1);
  max-width: 600px;
  width: 100%;
  margin: 5em auto 0;
}

.myForm-label {
  display: block;
  margin-bottom: 0.5em;
  color: #333;
  font-weight: bold;
}

.myForm-input {
  width: calc(100% - 22px);
  padding: 10px;
  margin-bottom: 1em;
  border: 1px solid #ddd;
  border-radius: 5px;
  box-sizing: border-box;
}

.myForm-input-textArea {
  width: calc(100% - 22px);
  padding: 10px;
  margin-bottom: 1em;
  border: 1px solid #ddd;
  border-radius: 5px;
  box-sizing: border-box;
  resize: none;
  outline: none;
  overflow: hidden;
}

.myForm-button {
  background-color: #0056b3;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1em;
  width: 100%;
  box-sizing: border-box;
}

.myForm-button:hover {
  background-color: #003d82;
}

.imgPrevUpdate {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 1em;
}

.imgPrevUpdate-imgContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 1em;
}

.imgPrevUpdate-img {
  max-width: 15vh;
  height: 15vh;
  border-radius: 5px;
  margin-right: 1em;
}

.imgPrevUpdate-deleteBtn {
  background-color: #ff0000;
  color: white;
  border: none;
  padding: 5px 10px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1em;
  box-sizing: border-box;
}

.content-block-button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px 15px;
  margin: 10px 5px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 0.9em;
  transition: background-color 0.3s ease;
}

.content-block-button:hover {
  background-color: #0056b3;
}

.myForm-field button {
  display: inline-block;
  width: auto;
}

.tag-label-btn {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 1em;
}

.add-tag-button {
  background-color: #0056b3;
  color: white;
  border: none;
  padding: 10px 20px;
  margin-left: 2em;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1em;
  box-sizing: border-box;
  margin-bottom: 1em;
}

.add-button {
  background: #fff; 
  border: none;
  border-radius: 5px;
  color: #fff; 
  padding: 10px 20px; 
  font-size: 16px; 
  cursor: pointer;
  transition: background-color 0.3s ease; 
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 0.5em;
}

.add-button:hover {
  background-color: #0056b3;
}

.sett-button {
  background: #fff; 
  border: none;
  border-radius: 5px;
  color: #fff; 
  padding: 6px 14px; 
  font-size: 12px; 
  cursor: pointer;
  transition: background-color 0.3s ease; 
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 1em 0.5em;
}

.sett-button:hover {
  background-color: #0056b3;
}
