@import url("https://fonts.googleapis.com/css?family=Poppins:400,500,600,700,800,900");

.flex-between {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.flex-between h3 {
  margin-right: 20px;
}

.margin-top-2 {
  margin-top: 2rem;
}

.custom-card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-clip: border-box;
  border: 1px solid #fff;
  border-radius: 0.25rem;
  box-shadow: 0 0.5rem 1rem #fff;
  padding: 1.25rem;
}

.user-list {
  color: #333;
}

.allUsers {
  margin-top: 12px;
  color: #fff;
}

.user-list .table {
  padding: 5px;
  width: 100%;
  overflow-x: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.user-list .table .search {
  width: 100%;
  max-width: 300px;
}

.user-list .table table {
  border-collapse: collapse;
  width: 100%;
  font-size: 1.4rem;
  background-color: #eee;
  margin: 0 auto;
}

.user-list .table table thead {
  border-top: 2px solid var(--light-blue);
  border-bottom: 2px solid var(--light-blue);
}

.user-list .table table th {
  border: 1px solid #eee;
}

.user-list .table table th,
.user-list .table table td {
  vertical-align: top;
  text-align: center;
  padding: 8px;
}

.user-list .table table th.icons,
.user-list .table table td.icons {
  display: flex;
  justify-content: center;
  align-items: center;
}

.user-list .table table th.icons > *,
.user-list .table table td.icons > * {
  margin-right: 7px;
  cursor: pointer;
  vertical-align: middle;
  align-self: center;
}

.user-list .table table tr {
  border-bottom: 1px solid #ccc;
}

.user-list .table table tr:nth-child(even) {
  background-color: #fff;
}

.user-list .table table tbody tr:hover {
  background-color: rgba(0, 0, 0, 0.3);
}

.sort {
  display: flex;
  justify-content: center;
  align-items: center;
}

.sort label {
  font-size: 1.4rem;
  font-weight: 500;
  margin: 0 5px;
}

.sort select {
  font-size: 1.6rem;
  font-weight: 300;
  padding: 4px 8px;
  margin: 0 5px 0 0;
  border: none;
  border-bottom: 2px solid #777;
  outline: none;
}

.pagination {
  display: flex;
  justify-content: center;
  padding: 20px 0;
  list-style: none;
}

.page-num {
  margin: 0 3px;
  padding: 10px 15px;
  border-radius: 5px;
  border: 1px solid #007bff;
  color: #007bff;
  cursor: pointer;
  text-decoration: none;
  transition: all 0.2s linear;
}

.page-num:hover {
  background-color: #007bff;
  color: #fff;
}

.activePage {
  background-color: #007bff;
  color: #fff;
  pointer-events: none;
}

@media (max-width: 768px) {
  .user-list .table table {
    font-size: 1.2rem;
  }

  .user-list .table .search {
    max-width: 100%;
  }

  .user-list .table table th,
  .user-list .table table td {
    padding: 5px;
  }

  .user-list .table table th.icons > *,
  .user-list .table table td.icons > * {
    margin-right: 5px;
  }

  .sort label {
    font-size: 1.2rem;
  }

  .sort select {
    font-size: 1.4rem;
  }

  .page-num {
    padding: 5px 10px;
  }

  .pagination {
    padding: 10px 0;
  }

  .margin-top-2 {
    margin-top: 1rem;
  }

  .custom-card {
    padding: 0.5rem;
  }

  .custom-card h3 {
    font-size: 1.4rem;
  }

  .custom-card p {
    font-size: 1.2rem;
  }

  .custom-card .flex-between {
    flex-direction: column;
  }

  .custom-card .flex-between h3 {
    margin-right: 0;
    margin-bottom: 10px;
  }

  .custom-card .flex-between .btn {
    margin-top: 10px;
  }

  .custom-card .flex-between .btn:last-child {
    margin-top: 0;
  }

  .custom-card .flex-between .btn:first-child {
    margin-right: 0;
  }

  .custom-card .flex-between .btn:last-child {
    margin-right: 0;
  }

  .custom-card .flex-between .btn {
    width: 100%;
  }
}

@media (max-width: 576px) {
  .user-list .table table {
    font-size: 1rem;
  }

  .user-list .table .search {
    max-width: 100%;
  }

  .user-list .table table th,
  .user-list .table table td {
    padding: 5px;
  }

  .user-list .table table th.icons > *,
  .user-list .table table td.icons > * {
    margin-right: 5px;
  }

  .sort label {
    font-size: 1.2rem;
  }

  .sort select {
    font-size: 1.4rem;
  }

  .page-num {
    padding: 5px 10px;
  }

  .pagination {
    padding: 10px 0;
  }

  .margin-top-2 {
    margin-top: 1rem;
  }

  .custom-card {
    padding: 0.5rem;
  }

  .custom-card h3 {
    font-size: 1.4rem;
  }

  .custom-card p {
    font-size: 1.2rem;
  }

  .custom-card .flex-between {
    flex-direction: column;
  }

  .custom-card .flex-between h3 {
    margin-right: 0;
    margin-bottom: 10px;
  }

  .custom-card .flex-between .btn {
    margin-top: 10px;
  }

  .custom-card .flex-between .btn:last-child {
    margin-top: 0;
  }

  .custom-card .flex-between .btn:first-child {
    margin-right: 0;
  }

  .custom-card .flex-between .btn:last-child {
    margin-right: 0;
  }

  .custom-card .flex-between .btn {
    width: 100%;
  }
}
