.about-title {
  font-size: 2rem;
  font-weight: 700;
  margin-bottom: 3rem;
  color: var(--color-white);
  text-align: center;
}

.about-card {
  padding: 2rem;
  border-radius: 5px;
  margin: 1rem;
  box-shadow: 0 0 15px 5px var(--shadow-color);
}

.about-card:hover {
    transform: scale(1.05);
    transition: all 0.3s ease-in-out;
}

.wave-effect {
    transition: all 0.5s ease;
    position: relative;
    overflow: hidden;
}

.wave-effect:hover::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 300%;
    height: 300%;
    background-color: rgba(255, 255, 255, 0.5);
    transform: translate(-50%, -50%);
    border-radius: 50%;
    animation: wave 2s ease-out 0.5s forwards;
}

@keyframes wave {
    0% {
        transform: translate(-50%, -50%) scale(0);
    }
    100% {
        transform: translate(-50%, -50%) scale(1);
        opacity: 0;
    }
}


.about-text {
  font-size: 1.2rem!important;
  font-weight: 500;
  color: var(--color-white);
  text-align: center;
}
