.navbar {
  display: flex;
  justify-content: space-between;
  padding: 15px 5%;
  align-items: center;
}

.nav-logo {
  font-size: 1.8rem;
  font-weight: 700;
  color: #fff;
  text-decoration: none;
  cursor: pointer;
  transition-duration: 0.4s;
}

.nav-logo:hover {
  text-shadow: 0 0 20px #fff;
}

.navbar-list {
  list-style: none;
}

.navbar-list li {
  display: inline-block;
  margin-left: 20px;
}

.navbar-list li a {
  font-size: 1.2rem;
  color: #fff;
  text-decoration: none;
  transition: all 0.2s;
  padding: 8px 30px;
  border-radius: 5px;
  transition: all 0.3s ease;
}

.navbar-list li a:hover,
.navbar-list li a.active {
  background-color: #fff;
  color: #1f2029;
}

#icon svg {
  width: 28px;
  height: 28px;
  color: #fff;
  cursor: pointer;
}

#icon svg:hover {
  color: #fff;
}

#checkbox,
#icon {
  display: none;
}

@media screen and (max-width: 768px) {
  .navbar-list {
    position: fixed;
    top: 50px;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: #1f2029;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    padding: 35vh 0;
    transition: 0.5s ease-in-out;
    opacity: 0;
    z-index: -1;
  }

  .navbar-list li {
    display: block;
  }

  .navbar-list li a {
    font-size: 1.5rem;
    color: #fff;
    text-decoration: none;
    transition: all 0.2s;
    border-radius: 5px;
    transition: all 0.3s ease;
  }

  #icon {
    display: block;
  }

  #checkbox:checked ~ ul {
    opacity: 1;
    z-index: 1;
  }
}

@media screen and (max-width: 601px) {
  .logo h1 {
    font-size: 2.5rem;
  }
  .navbar {
    margin-bottom: 3rem;
  }
}

@media screen and (max-width: 400px) {
  .logo h1 {
    font-size: 1.5rem;
  }
}
