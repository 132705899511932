.search {
  margin: 5px 0;
  position: relative;
  flex: 1;
  display: flex;
  align-items: center;
  background-color: #f2f2f2;
  border-radius: 25px;
  padding: 1.5rem 1rem;
  transition: box-shadow 0.3s ease;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.search .icon-search {
  color: #777;
}

.searchInput {
  flex-grow: 1;
  border: none;
  background: transparent;
  margin-left: 1rem;
  font-size: 1.9rem;
  outline: none;
  transition: all 0.3s ease;
}