.profile-container {
  max-width: 850px;
  margin: 3rem auto 0;
  padding: 0 20px;
}

.hover-effect {
  transition: transform 0.3s ease-in-out;
}

.hover-effect:hover {
  transform: scale(1.05);
}

.centered-row {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.full-width {
  width: 100% !important;
}

.custom-card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}

.centered-text {
  text-align: center;
}

.custom-card-body {
  flex: 1 1 auto;
  padding: 1.25rem;
}

.circle {
  border-radius: 50%;
  width: 150px;
  height: 150px;
  cursor: pointer;
}

.margin-bottom {
  margin-bottom: 1rem;
}

.margin-top {
  margin-top: 1rem;
}

.custom-form-group {
  position: relative;
  display: block;
  margin: 0;
  padding: 0;
}

.form-control-file {
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  padding: 0.5rem 0.75rem;
  height: 44px;
  font-size: large;
}

.form-group-flex {
  display: flex;
  justify-content: space-between;
  gap: 1rem;
}

.form-group {
  position: relative;
  display: block;
  margin: 0;
  padding: 0;
}
.profile-label {
  font-weight: 600;
  color: #fff;
  margin-bottom: 0.5rem;
}
.forgot-input {
  padding: 13px 20px;
  padding-left: 55px;
  height: 48px;
  width: 100%;
  font-weight: 500;
  border-radius: 4px;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0.5px;
  outline: none;
  color: #c4c3ca;
  background-color: #000000;
  border: none;
  -webkit-transition: all 200ms linear;
  transition: all 200ms linear;
  box-shadow: 0 4px 8px 0 rgba(21, 21, 21, 0.2);
}
.forgot-input:focus,
.forgot-input:active {
  border: none;
  outline: none;
  box-shadow: 0 4px 8px 0 rgba(21, 21, 21, 0.2);
}
.input-icon {
  position: absolute;
  left: 18px;
  height: 48px;
  font-size: 24px;
  line-height: 48px;
  text-align: left;
  color: #1f93ff;
  -webkit-transition: all 200ms linear;
  transition: all 200ms linear;
}

.form-group input:-ms-input-placeholder {
  color: #fafafa;
  opacity: 0.7;
  -webkit-transition: all 200ms linear;
  transition: all 200ms linear;
}
.form-group input::-moz-placeholder {
  color: #c4c3ca;
  opacity: 0.7;
  -webkit-transition: all 200ms linear;
  transition: all 200ms linear;
}
.form-group input:-moz-placeholder {
  color: #c4c3ca;
  opacity: 0.7;
  -webkit-transition: all 200ms linear;
  transition: all 200ms linear;
}
.form-group input::-webkit-input-placeholder {
  color: #c4c3ca;
  opacity: 0.7;
  -webkit-transition: all 200ms linear;
  transition: all 200ms linear;
}
.form-group input:focus:-ms-input-placeholder {
  opacity: 0;
  -webkit-transition: all 200ms linear;
  transition: all 200ms linear;
}
.form-group input:focus::-moz-placeholder {
  opacity: 0;
  -webkit-transition: all 200ms linear;
  transition: all 200ms linear;
}
.form-group input:focus:-moz-placeholder {
  opacity: 0;
  -webkit-transition: all 200ms linear;
  transition: all 200ms linear;
}
.form-group input:focus::-webkit-input-placeholder {
  opacity: 0;
  -webkit-transition: all 200ms linear;
  transition: all 200ms linear;
}

.custom-file-input {
  color: transparent;
}
.custom-file-input::-webkit-file-upload-button {
  visibility: hidden;
}
.custom-file-input::before {
  content: "Select some files";
  color: black;
  display: inline-block;
  background: -webkit-linear-gradient(top, #f9f9f9, #e3e3e3);
  border: 1px solid #999;
  border-radius: 3px;
  padding: 5px 8px;
  outline: none;
  white-space: nowrap;
  -webkit-user-select: none;
  cursor: pointer;
  text-shadow: 1px 1px #fff;
  font-weight: 700;
  font-size: 10pt;
}
.custom-file-input:hover::before {
  border-color: black;
}

.input-icon-custom {
  position: absolute;
  top: 50%;
  left: 10px;
  transform: translateY(-50%);
  margin-top: 1.5rem;
  color: #007bff;
  font-size: 25px;
}

.custom-card-footer {
  padding: 0.75rem 1.25rem;
  background-color: rgba(0, 0, 0, 0.03);
  border-top: 1px solid rgba(0, 0, 0, 0.125);
  color: #fff;
  text-align: center;
}

.hover-message {
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s, opacity 0.5s linear;
}

.email-input:hover .hover-message {
  visibility: visible;
  opacity: 1;
  color: rgb(236, 36, 36);
}

.textarea {
  width: 100%;
  height: 150px;
  box-sizing: border-box;
  border-radius: 4px;
  border-color: #1f2029;
  background-color: #1f2029;
  font-size: 16px;
  color: #c4c3ca;
  padding: 12px 60px;
}

@media (min-width: 768px) {
  .full-width {
    width: 66.67%;
  }
}
