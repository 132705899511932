@import url("https://fonts.googleapis.com/css?family=Poppins:400,500,600,700,800,900");

.change-pass-container {
  max-width: 850px;
  margin: 3rem auto 0;
  padding: 0 20px;
}

.margin-top-3 {
  margin-top: 3rem;
}

.margin-top-2 {
  margin-top: 2rem;
}

.centered-row {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.custom-card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}

.centered-text {
  margin-top: 20px;
  text-align: center;
  color: #fff;
}

.custom-card-body {
  flex: 1 1 auto;
  padding: 1.25rem;
  margin: 0 150px;
}

.form-group-flex {
  display: flex;
  justify-content: space-between;
  gap: 1rem;
}

.form-group {
  position: relative;
  display: block;
  margin: 0;
  padding: 0;
}

.forgot-input {
  padding: 13px 20px;
  padding-left: 55px;
  height: 48px;
  width: 100%;
  font-weight: 500;
  border-radius: 4px;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0.5px;
  outline: none;
  color: #c4c3ca;
  background-color: #111111;
  border: none;
  -webkit-transition: all 200ms linear;
  transition: all 200ms linear;
  box-shadow: 0 4px 8px 0 rgba(21, 21, 21, 0.2);
}
.forgot-input:focus,
.forgot-input:active {
  border: none;
  outline: none;
  box-shadow: 0 4px 8px 0 rgba(21, 21, 21, 0.2);
}

.toggle-icon {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  cursor: pointer;
}

@media (min-width: 768px) {
  .full-width {
    width: 66.67%;
  }
}

@media (max-width: 768px) {
  .custom-card-body {
    margin: 0 10px;
  }
}
