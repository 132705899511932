  @property --angle {
      syntax: '<angle>';
      initial-value: 0deg;
      inherits: false;
  }
  
  .spiral{
    display: flex;
    align-items: center;
    position: absolute;
    font-family: "sans-serif";
  }
  
  @keyframes spiral{
    0%{
      --angle: 0deg;
    }
    100%{
      --angle: 360deg;
    }
  }
  
  .character{
    font-size: 0.6rem;
    color: white;
    text-transform: uppercase;
    transform: translateY(calc(sin(var(--angle)) * 20px)) scale(calc(cos(var(--angle)) * 0.5 + 0.5));
    animation: spiral 4s linear infinite;
    color: var(--text-color);
  }
  
  @media (max-width: 490px){
    .character{
      font-size: 0.5rem;
    }
  }

  @media (min-width: 800px){
    .character{
      font-size: 0.8rem;
    }
  }